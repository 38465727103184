import React from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Card, CardBody } from "reactstrap";
import bgLogoImg from "../../assets/images/bg-logo.png";
import logoImg from "../../assets/images/logo.svg";
import lightLogoImg from "../../assets/images/logo-light.svg";

const FormWrapperCard = ({ title, description, children }) => {
  const { t } = useTranslation();

  return (
    <Card className="overflow-hidden">
      <div className="bg-primary bg-soft">
        <Row>
          <Col className="col-7">
            <div className="text-primary p-4">
              <h5 className="text-primary">{t(title)}</h5>
              <p>{t(description)}</p>
            </div>
          </Col>

          <Col className="col-5 align-self-end">
            <img src={bgLogoImg} alt="bg-logo" className="img-fluid" />
          </Col>
        </Row>
      </div>

      <CardBody className="pt-0">
        <div className="auth-logo">
          <div className="auth-logo-light">
            <div className="avatar-md profile-user-wid mb-4">
              <span className="avatar-title rounded-circle bg-light">
                <img
                  src={lightLogoImg}
                  alt="logo"
                  className="rounded-circle"
                  height="34"
                />
              </span>
            </div>
          </div>

          <div className="auth-logo-dark">
            <div className="avatar-md profile-user-wid mb-4">
              <span className="avatar-title rounded-circle bg-light">
                <img
                  src={logoImg}
                  alt="logo"
                  className="rounded-circle"
                  height="34"
                />
              </span>
            </div>
          </div>
        </div>

        <div className="mt-3">{children}</div>
      </CardBody>
    </Card>
  );
};

export default FormWrapperCard;
