import React from "react";
import Topbar from "../components/topbar";
import Sidebar from "../components/sidebar";
import Footer from "../components/footer";

const AuthLayout = ({ children }) => {
  return (
    <div id="layout-wrapper">
      <Topbar />

      <Sidebar />

      <div className="main-content page-content">{children}</div>

      <Footer />
    </div>
  );
};

export default AuthLayout;
