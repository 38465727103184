import React, { useEffect, useState } from "react";
import { Badge } from "reactstrap";
import { useTranslation } from "react-i18next";

const getBadgeColor = (saleType) => {
  switch (saleType) {
    case "PutOnSale":
      return "primary";
    case "InstantSalePrice":
      return "info";
    case "UnlockOnePurchased":
      return "warning";
    case "NotOnSale":
      return "secondary";
    default:
      return "dark";
  }
};

const SaleTypeBadge = ({ saleType }) => {
  const { t } = useTranslation();
  const [badgeColor, setBadgeColor] = useState("");

  useEffect(() => {
    setBadgeColor(getBadgeColor(saleType));
  }, [saleType]);

  return (
    <Badge className={`bg-${badgeColor}`}>
      {t(`constants.sale-types.${saleType}`)}
    </Badge>
  );
};

export default SaleTypeBadge;
