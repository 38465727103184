import React from "react";
import { Card, CardBody, Col, Media, UncontrolledTooltip } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AssetCover from "../../components/asset/AssetCover";
import SaleTypeBadge from "../../components/badge/SaleType";
import { assetDetailsPath } from "../../constants/assets";
import { getAssetPrice } from "../../helpers/assets";

const AssetsGridItem = ({ asset }) => {
  const { t } = useTranslation();

  return (
    <Col xl="4" sm="6">
      <Card>
        <CardBody>
          <Media>
            <div className="avatar-md me-4">
              <span className="avatar-title rounded-circle bg-light text-danger font-size-16">
                <AssetCover asset={asset} height="30" />
              </span>
            </div>

            <Media className="overflow-hidden" body>
              <h5 className="text-truncate font-size-15">
                <Link
                  to={`${assetDetailsPath}/${asset._id}`}
                  className="text-dark"
                >
                  {asset.title}
                </Link>
              </h5>

              <p className="text-truncate text-muted mb-2">
                {asset.description}
              </p>

              <p className="text-muted mb-4">{`@${asset.ownerId.nickname}`}</p>
            </Media>
          </Media>
        </CardBody>

        <div className="px-4 py-3 border-top">
          <ul className="list-inline mb-0">
            <li className="list-inline-item me-3">
              <h5 className="mb-0">{getAssetPrice(asset)}</h5>
            </li>

            <li className="list-inline-item me-3">
              <SaleTypeBadge saleType={asset.saleType} />
            </li>

            <li className="list-inline-item me-3" id={`likes_${asset._id}`}>
              <i className="bx bx-heart me-1" />

              <span>{asset.likes}</span>

              <UncontrolledTooltip
                placement="top"
                target={`likes_${asset._id}`}
              >
                {t("likes")}
              </UncontrolledTooltip>
            </li>
          </ul>
        </div>
      </Card>
    </Col>
  );
};

export default AssetsGridItem;
