import React from "react";
import { useDispatch } from "react-redux";
import { DropdownMenu, DropdownItem } from "reactstrap";
import { Link } from "react-router-dom";
import { LOGOUT_USER } from "../../store/AuthSlice";

const ProfileDropdownList = () => {
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(LOGOUT_USER());
  };

  return (
    <DropdownMenu className="dropdown-menu-end">
      <Link className="dropdown-item" to="/profile" role="menuitem">
        <i className="bx bx-user font-size-16 align-middle me-1" />
        <span>Profile</span>
      </Link>

      <Link className="dropdown-item" to="/assets" role="menuitem">
        <span className="badge bg-success float-end">11</span>
        <i className="bx bx-wrench font-size-16 align-middle me-1" />
        <span>Settings</span>
      </Link>

      <div className="dropdown-divider" />

      <DropdownItem tag="button" onClick={logout}>
        <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
        <span>Logout</span>
      </DropdownItem>
    </DropdownMenu>
  );
};

export default ProfileDropdownList;
