import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import PageTitle from "../../components/page/Title";
import UserList from "../../components/userList";
import { notifyError } from "../../helpers/notifications";
import { LOGOUT_USER } from "../../store/AuthSlice";
import { getUsers } from "../../store/UserSlice";

const Users = ({ }) => {
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const { t } = useTranslation();

  const retrieveUsers = async () => {
    try {
      let retrievedUsers = await dispatch(getUsers({})).unwrap();
      setUsers(retrievedUsers);
    } catch (e) {
      console.error(e);
      notifyError(e);
      // if(e.code === 401){
      //   dispatch(LOGOUT_USER());
      // }
    }

  }

  useEffect(() => {
    retrieveUsers();
  }, [])

  return (
    <React.Fragment>
      <PageTitle text="users.title" />
      <div className="pt-5">
        {
          /* 1. create user details component -> should contain information about the user and a button that enables manual KYC enlistment (?)
          /* 2. create a user thumbnail component -> should just have an image and the name
          /* 3. create user list component -> just lists user thumbnail components.
          /* 4. display the user list component here 
          */
        }
        <Row className="p-3">
          <Col className="col-xl-2 col-lg-2 col-md-auto col-sm-auto col-xs-auto">
            <h5> {t("users.full-name")} </h5>
          </Col>
          <Col className="col-xl-2 col-lg-2 col-md-auto col-sm-auto col-xs-auto">
            <h5> {t("users.nickname")} </h5>
          </Col>
          <Col className="col-xl-2 col-lg-2 col-md-auto col-sm-auto col-xs-auto">
            <h5> {t("users.email")} </h5>
          </Col>
          <Col className="col-xl-2 col-lg-2 col-md-auto col-sm-auto col-xs-auto">
            <h5> {t("users.role")} </h5>
          </Col>
          <Col className="col-xl-2 col-lg-2 col-md-auto col-sm-auto col-xs-auto">
            <h5> {t("users.level")} </h5>
          </Col>
          <Col className="col-xl-2 col-lg-4 col-md-auto col-sm-auto col-xs-auto text-end"></Col>
        </Row>
        <UserList users={users} />
      </div>
    </React.Fragment>
  );
}

export default Users;