import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "./AuthSlice";
import assetReducer from "./AssetSlice";
import ethereumReducer from "./EthereumSlice";
import userReducer from "./UserSlice";

const storePersistKeys = {
  auth: "nft_gallery_console_auth",
};

const reducer = combineReducers({
  auth: persistReducer(
    { key: storePersistKeys.auth, storage: storage },
    authReducer
  ),
  asset: assetReducer,
  ethereum: ethereumReducer,
  user: userReducer
});

const rootReducer = (state, action) => {
  if (action.type === "store/reset") {
    state = undefined;
  }
  return reducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);

export const resetPersistor = () => {
  persistStore(store);
};

export default store;
