export const isMobile = () =>
  /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

export const changeBodyAttribute = (attribute, value) => {
  if (document.body) document.body.setAttribute(attribute, value);
  return true;
};

export const manageBodyClass = (cssClass, action = "toggle") => {
  switch (action) {
    case "add":
      if (document.body) document.body.classList.add(cssClass);
      break;
    case "remove":
      if (document.body) document.body.classList.remove(cssClass);
      break;
    default:
      if (document.body) document.body.classList.toggle(cssClass);
      break;
  }

  return true;
};

export const changeSidebarType = (sidebarType, isMobile) => {
  try {
    switch (sidebarType) {
      case "compact":
        changeBodyAttribute("data-sidebar-size", "small");
        manageBodyClass("sidebar-enable", "remove");
        manageBodyClass("vertical-collpsed", "remove");
        break;
      case "icon":
        changeBodyAttribute("data-keep-enlarged", "true");
        manageBodyClass("vertical-collpsed", "add");
        break;
      case "condensed":
        // manageBodyClass("sidebar-enable", "add")
        // if (!isMobile) manageBodyClass("vertical-collpsed", "add")
        manageBodyClass("sidebar-enable", "add");
        if (window.screen.width >= 992) {
          manageBodyClass("vertical-collpsed", "remove");
          manageBodyClass("sidebar-enable", "remove");
          manageBodyClass("vertical-collpsed", "add");
          manageBodyClass("sidebar-enable", "add");
        } else {
          manageBodyClass("sidebar-enable", "add");
          manageBodyClass("vertical-collpsed", "add");
        }
        break;
      default:
        changeBodyAttribute("data-sidebar-size", "");
        manageBodyClass("sidebar-enable", "remove");
        if (!isMobile) manageBodyClass("vertical-collpsed", "remove");
        break;
    }
  } catch (error) {}
};

export const changeSidebarTheme = (theme) =>
  changeBodyAttribute("data-sidebar", theme);
