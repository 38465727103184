import React, { useEffect, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Button, Col, Row } from "reactstrap";
import { retrieveAsset } from "../../store/AssetSlice";
import { getAssetCoverSrc, getChain, getStandard } from "../../helpers/assets";
import PageTitle from "../../components/page/Title";
import FormikForm from "../../components/form/FormikForm";
import { TextInput } from "../../components/form/FormikInputs";
import { getTokenRoyalty, initializeContract, setTokenRoyalty } from "../../store/EthereumSlice";
import { useLoader } from "../../providers/loader";
import { notifyError, notifySuccess } from "../../helpers/notifications";

const validationSchema = Yup.object().shape({
  ownershare: Yup.number()
    .required("Share is required")
    .min(0, "A positive share is needed")
    .max(10000, "Share must be up to 1000 or less").test('should-add-to-10000', 'Basis points should add up to 10000', (value, context) => {
      return (context.parent.ownershare + context.parent.sellershare + context.parent.platformshare === 10000);
    }),
  sellershare: Yup.number()
    .required("Share is required")
    .min(0, "A positive share is needed")
    .max(10000, "Share must be up to 1000 or less").test('should-add-to-10000', 'Basis points should add up to 10000', (value, context) => {
      return (context.parent.ownershare + context.parent.sellershare + context.parent.platformshare === 10000);
    }),
  platformshare: Yup.number()
    .required("Share is required")
    .min(0, "A positive share is needed")
    .max(10000, "Share must be up to 1000 or less").test('should-add-to-10000', 'Basis points should add up to 10000', (value, context) => {
      return (context.parent.ownershare + context.parent.sellershare + context.parent.platformshare === 10000);
    }),
});

const parseBlockchainRoyalties = (object = {}) => {
  return {
    owner: {
      address: object?.[0] || "",
      royalty: object?.[3] || ""
    },
    creator: {
      address: object?.[1] || "",
      royalty: object?.[4] || ""
    },
    platform: {
      address: object?.[2] || "",
      royalty: object?.[5] || ""
    }
  };
}

const initialValues = {
  ownershare: 0,
  sellershare: 0,
  platformshare: 0
}

const AssetDetails = () => {
  const { assetId } = useParams();
  const dispatch = useDispatch();
  const { currentAsset } = useSelector((store) => store.asset);
  const [creator, setCreator] = useState({ address: "", royalty: 0 });
  const [seller, setSeller] = useState({ address: "", royalty: 0 });
  const [platform, setPlatform] = useState({ address: "", royalty: 0 });
  const [formData, setFormData] = useState(initialValues);
  const [standard, setStandard] = useState(0);
  const [chain, setChain] = useState(0);
  const { setLoading, setText } = useLoader();
  const [disable, setDisable] = useState(false);

  const fetchAsset = useCallback(async () => {
    try {
      return await dispatch(retrieveAsset({ assetId })).unwrap();
    } catch (error) {
      console.error(error);
    }
  }, [dispatch, assetId]);


  const fetchAssetRoyalties = useCallback(async (standard, assetId, chain) => {
    try {
      await dispatch(initializeContract({ contractName: "SaysoonMatch", chainId: chain })).unwrap();

      const info = await dispatch(getTokenRoyalty({ ERCstandard: standard, assetId })).unwrap();

      return parseBlockchainRoyalties(info);
    } catch (error) {
      console.error(error);
    }
  }, [dispatch])

  const updateAssetRoyalties = useCallback(async (standard, chain, values) => {
    try {
      setLoading(true);
      setText("Updating");
      await dispatch(initializeContract({ contractName: "SaysoonMatch", chainId: chain })).unwrap();
      await dispatch(setTokenRoyalty(
        {
          ERCStandard: standard,
          assetId,
          receiverOwner: seller?.address,
          receiverCreator: creator?.address,
          receiverPlatform: platform.address,
          feeNumeratorOwner: values?.ownershare || seller?.royalty,
          feeNumeratorCreator: values?.sellershare || creator?.royalty,
          feeNumeratorPlatform: values?.platformshare || platform?.royalty
        }
      )).unwrap();
      notifySuccess('Royalties updated');
    } catch (error) {
      console.error(error);
      notifyError(error);
    } finally {
      setLoading(false);
    }

  }, [assetId, seller, creator, platform, dispatch])

  const handleSubmit = async (values) => {
    try {
      await updateAssetRoyalties(standard, chain, values);
    } catch (error) {
      console.error(error);
      notifyError(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchAsset().then(async (res) => {
      setStandard(getStandard(res));
      setChain(getChain(res));
      if (res?.standard === 'NotMinted' || !res?.tokenId) {
        setDisable(true);
        // doesn't have royalty info yet
        // disable setting royalties, especially if it has no tokenId
        // and inform the user
        return;
      }
      const { creator, owner, platform } = await fetchAssetRoyalties(getStandard(res), res?._id, getChain(res));
      setCreator(creator);
      setSeller(owner);
      setPlatform(platform);

      setFormData({
        ownershare: owner?.royalty,
        sellershare: creator?.royalty,
        platformshare: platform?.royalty,
      });
    }).catch((error) => {
      notifyError(error);
    }).finally(() => {
      setLoading(false);
    })
  }, [fetchAsset, fetchAssetRoyalties]);

  return (
    <>
      <div className="asset-details">
        <PageTitle
          text="assets-details-page.title"
          textParams={{ asset: currentAsset.title }}
        />
        <div className="img-container">
          <img
            src={getAssetCoverSrc(currentAsset, 1000)}
            alt={currentAsset.title}
          />
        </div>
        <FormikForm
          initialValues={formData}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {disable &&
            <div className="p-2 text-center text-warning">
              Asset not yet minted
            </div>
          }
          <TextInput
            disabled={disable}
            name="ownershare"
            label="Owner Share"
            placeholder={"e.x. 10"}
          />
          <Row>
            <Col sm={6}>
              <TextInput
                disabled={disable}
                name="sellershare"
                label="Creator Share"
                placeholder={"e.x. 10"}
              />
            </Col>
            <Col sm={6}>
              <TextInput
                disabled={disable}
                name="platformshare"
                label="Platform Share"
                placeholder={"e.x. 10"}
              />
            </Col>
          </Row>
          <Button disabled={disable} type="submit">Update</Button>
        </FormikForm>
      </div>
      <div className="pt-3">
        <div>Creator: {creator.address}</div>
        <div>Owner: {seller.address}</div>
        <div>Platform: {platform.address}</div>
      </div>
    </>
  );
};

export default AssetDetails;
