import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api";
var jwt = require("jsonwebtoken");

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const response = await api.UserService.loginUser({
        email,
        password,
      });

      const decodedToken = jwt.decode(
        response.body.data.token,
        "weriu34sdmkd326842lfjewrmekoifrqwei"
      );

      return {
        userId: decodedToken.user.id,
        token: response.body.data.token,
      };

    } catch (error) {
      return rejectWithValue(error?.body?.error || error);
    }
  }
);

export const retrieveAuthUser = createAsyncThunk(
  "auth/retrieveAuthUser",
  async ({ userId }, { rejectWithValue }) => {
    try {
      const response = await api.UserService.retrieveUser(userId);
      return response.body.data;
    } catch (error) {
      return rejectWithValue(error?.body?.error || error);
    }
  }
);

let initialState = {
  status: "",
  error: {},
  authToken: "",
  userId: "",
  authUser: {},
  isLoggedIn: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    LOGOUT_USER(state) {
      state.authToken = "";
      state.isLoggedIn = false;
      state.userId = "";
    },
  },
  extraReducers: {
    /* ---- LOGIN_USER ---- */
    [String(loginUser.pending)]: (state, action) => {
      state.status = "loading";
    },
    [String(loginUser.fulfilled)]: (state, action) => {
      state.status = "success";
      state.authToken = `Bearer ${action.payload.token}`;
      state.userId = action.payload.userId;
      state.isLoggedIn = true;
    },
    [String(loginUser.rejected)]: (state, action) => {
      state.status = "error";
      state.error = action.payload;
      state.authToken = "";
      state.isLoggedIn = false;
    },
    /* ---- RETRIEVE_AUTH_USER ---- */
    [String(retrieveAuthUser.pending)]: (state, action) => {
      state.status = "loading";
    },
    [String(retrieveAuthUser.fulfilled)]: (state, action) => {
      state.status = "success";
      // state.authToken = `Bearer ${action.payload.token}`;
      state.isLoggedIn = true;
    },
    [String(retrieveAuthUser.rejected)]: (state, action) => {
      state.status = "error";
      state.error = action.payload;
      state.authToken = "";
      state.userId = "";
      state.isLoggedIn = false;
    },
  },
});

export const { LOGOUT_USER } = authSlice.actions;

export default authSlice.reducer;
