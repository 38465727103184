import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api";

export const initializeContract = createAsyncThunk(
  "ethereum/initializeContract",
  async ({contractName, chainId, at }, { getState, rejectWithValue }) => {
    try {
      const response = await api.EthereumService.initializeContract(contractName, chainId, at);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const setTokenRoyalty = createAsyncThunk(
  "ethereum/setTokenRoyalty",
  async ({
    ERCStandard,
    assetId,
    receiverOwner,
    receiverCreator,
    receiverPlatform,
    feeNumeratorOwner,
    feeNumeratorCreator,
    feeNumeratorPlatform }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { contract } = state.ethereum;
      const response = await api.EthereumService.setTokenRoyalty(
        contract,
        ERCStandard,
        assetId,
        receiverOwner,
        receiverCreator,
        receiverPlatform,
        feeNumeratorOwner,
        feeNumeratorCreator,
        feeNumeratorPlatform);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const setDefaultRoyalty = createAsyncThunk(
  "ethereum/setDefaultRoyalty",
  async ({
    receiverOwner,
    receiverCreator,
    receiverPlatform,
    feeNumeratorOwner,
    feeNumeratorCreator,
    feeNumeratorPlatform }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { contract } = state.ethereum;
      const response = await api.EthereumService.setDefaultRoyalty(
        contract,
        receiverOwner,
        receiverCreator,
        receiverPlatform,
        feeNumeratorOwner,
        feeNumeratorCreator,
        feeNumeratorPlatform);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDefaultRoyalty = createAsyncThunk(
  "ethereum/getDefaultRoyalty",
  async ({}, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { contract } = state.ethereum;
      const response = await api.EthereumService.getDefaultRoyalty(contract);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getTokenRoyalty = createAsyncThunk(
  "ethereum/getTokenRoyalty",
  async ({ ERCstandard, assetId }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { contract } = state.ethereum;
      const response = await api.EthereumService.getTokenRoyalty(contract, ERCstandard, assetId);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

let initialState = {
  status: "",
  error: {},
  contract: {}
};

const ethereumSlice = createSlice({
  name: "ethereum",
  initialState,
  reducers: {},
  extraReducers: {
    /* ---- INITIALIZE_CONTRACT ---- */
    [String(initializeContract.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(initializeContract.fulfilled)]: (state, action) => {
      state.state = "success";
      state.contract = action.payload;
    },
    [String(initializeContract.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- SET_ROYALTY ---- */
    [String(setTokenRoyalty.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(setTokenRoyalty.fulfilled)]: (state, action) => {
      state.state = "success";
      state.receipt = action.payload;
    },
    [String(setTokenRoyalty.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- GET_ROYALTY ---- */
    [String(getTokenRoyalty.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(getTokenRoyalty.fulfilled)]: (state, action) => {
      state.state = "success";
      state.receipt = action.payload;
    },
    [String(getTokenRoyalty.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
  },
});

export default ethereumSlice.reducer;
