import Web3 from "web3";
import HDWalletProvider from "@truffle/hdwallet-provider"
import { ethereum } from "../constants/appSettings";
import { getContractArtifact } from "../helpers/ethereum";
export default class EthereumService {

  async initializePlatformWeb3(chainID = null) {
    return new Promise(async (resolve, reject) => {

      try {
        const chainId = chainID;
        let providerUrl;

        switch (chainId) {
          case 3:
            // Ropsten
            providerUrl = ethereum.ropstenUrl;
            break;
          case 4:
            // Rinkeby
            providerUrl = ethereum.rinkebyUrl;
            break;
          case 5:
            // Goerli
            providerUrl = ethereum.goerliUrl;
            break;
          case 4500:
            // Saysoon
            providerUrl = ethereum.saysoonUrl;
            break;
          case 137:
            // Polygon Mainnet
            providerUrl = ethereum.polygonUrl;
            break;
          case 80001:
            // Polygon Testnet
            providerUrl = ethereum.mumbaiUrl;
            break;
          default:
            break;
        }

        const provider = new HDWalletProvider({
          mnemonic: {
            phrase: ethereum.mnemonicPhrase
          },
          providerOrUrl: providerUrl
        });

        // const provider = new Web3.providers.HttpProvider(providerUrl);

        const platformWeb3 = new Web3(provider);
        provider.engine.stop();

        // const hasPrefix = ethereum.platformPK.substring(0, 2) === '0x';

        // const account = platformWeb3.eth.accounts.privateKeyToAccount(
        //   '0x' + ethereum.platformPK
        // );

        // const account = platformWeb3.eth.accounts.wallet.add({
        //   privateKey: hasPrefix ? '' : '0x' + ethereum.platformPK,
        //   address: provider.getAddress()
        // });
        // platformWeb3.eth.accounts.wallet.add(account);
        platformWeb3.eth.defaultAccount = provider.getAddress();

        resolve(platformWeb3);
      } catch (e) {
        reject(e)
      }
    })


  }

  async createContractObject(address, abi, chainId = null) {
    return new Promise(async (resolve, reject) => {
      try {
        if (!address || !abi) {
          console.log('Invalid parameters, createContractObject aborted...');
          reject('Invalid parameters, createContractObject aborted');
        }

        const chainID = chainId;
        const provider = await this.initializePlatformWeb3(chainID);
        const contract = new provider.eth.Contract(abi, address);

        resolve(contract);
      } catch (e) {
        reject(e);
      }
    });
  }

  async initializeContract(contractName = "DigitalAsset", chainId = null, at = null) {
    return new Promise(async (resolve, reject) => {
      const chainID = chainId;
      let contract;
      const artifact = getContractArtifact(contractName);

      // console.log("ETH Environment ", ethereum.environment);
      // console.log("Network id is: ", chainID);
      let networkData;

      // using address from truffle deployment or specified at address 
      if (at) {
        networkData.address = at;
      } else {
        networkData = artifact.networks[chainID];
      }

      // console.log("Networks: ", artifact.networks);

      if (networkData) {
        const abi = artifact.abi;
        const address = networkData.address;
        // console.log("Contract being used: ", address);

        contract = await this.createContractObject(networkData.address, abi, chainID);

        resolve(contract);
        // const totalSupply = await contract.methods.totalSupply().call();
      } else {
        reject("Smart contract not deployed to detected network.");
      }
    });

  }

  // ---------------------------- NFT Methods (ERC1155 & ERC721) ----------------------------
  async setTokenRoyalty(
    contract,
    ERCStandard,
    assetId,
    receiverOwner,
    receiverCreator,
    receiverPlatform,
    feeNumeratorOwner,
    feeNumeratorCreator,
    feeNumeratorPlatform) {
    return new Promise(async (resolve, reject) => {

      const gasEstimate = await contract.methods.setTokenRoyalty(ERCStandard,
        assetId,
        receiverOwner,
        receiverCreator,
        receiverPlatform,
        feeNumeratorOwner,
        feeNumeratorCreator,
        feeNumeratorPlatform).estimateGas({ from: ethereum.platformAddress });

      const chainId = contract.currentProvider.chainId;
      const web3 = await this.initializePlatformWeb3(chainId);
      const nextNonce = '0x' + (await web3.eth.getTransactionCount(ethereum.platformAddress)).toString(16);
      const gasPrice = await web3.eth.getGasPrice();

      contract.methods.setTokenRoyalty(ERCStandard,
        assetId,
        receiverOwner,
        receiverCreator,
        receiverPlatform,
        feeNumeratorOwner,
        feeNumeratorCreator,
        feeNumeratorPlatform)
        .send({
          from: ethereum.platformAddress,
          gasPrice: gasPrice,
          gas: gasEstimate,
          value: 0,
          nonce: nextNonce
        })
        .once("receipt", (receipt) => {
          resolve(receipt);
        })
        .once("error", (error) => {
          reject(error);
        });
    });
  }

  async getTokenRoyalty(contract, ERCStandard, assetId) {
    return new Promise((resolve, reject) => {
      contract.methods.royaltyInfo(ERCStandard, assetId, 10000).call().then((res) => {
        resolve(res);
      }).catch(e => {
        console.error('Could not get royalty information: ', e);
        reject(e);
      })
    })
  }

  async setDefaultRoyalty(
    contract,
    receiverOwner,
    receiverCreator,
    receiverPlatform,
    feeNumeratorOwner,
    feeNumeratorCreator,
    feeNumeratorPlatform) {
    return new Promise(async (resolve, reject) => {

      const gasEstimate = await contract.methods.setDefaultRoyalty(
        receiverOwner,
        receiverCreator,
        receiverPlatform,
        feeNumeratorOwner,
        feeNumeratorCreator,
        feeNumeratorPlatform).estimateGas({ from: ethereum.platformAddress });

      const chainId = contract.currentProvider.chainId;
      const web3 = await this.initializePlatformWeb3(chainId);
      const nextNonce = '0x' + (await web3.eth.getTransactionCount(ethereum.platformAddress)).toString(16);
      const gasPrice = await web3.eth.getGasPrice();

      contract.methods.setDefaultRoyalty(
        receiverOwner,
        receiverCreator,
        receiverPlatform,
        feeNumeratorOwner,
        feeNumeratorCreator,
        feeNumeratorPlatform)
        .send({
          from: ethereum.platformAddress,
          gasPrice: gasPrice,
          gas: gasEstimate,
          value: 0,
          nonce: nextNonce
        })
        .once("receipt", (receipt) => {
          resolve(receipt);
        })
        .once("error", (error) => {
          reject(error);
        });
    });
  }

  async getDefaultRoyalty(contract) {
    return new Promise((resolve, reject) => {
      contract.methods.getDefaultRoyalty().call().then((res) => {
        resolve(res);
      }).catch(e => {
        console.error('Could not get default royalty information: ', e);
        reject(e);
      })
    })
  }
}