import React from "react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { useDispatch } from "react-redux";
import { upgraderUser } from "../../store/UserSlice";
import { notifyError, notifySuccess } from "../../helpers/notifications";
import { useTranslation } from "react-i18next";

const UserPreview = ({ image, userInfo }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const getFirstmame = (user) => {
    return user?.profiles?.[0]?.profileId?.firstname;
  }

  const getLastname = (user) => {
    return user?.profiles?.[0]?.profileId?.lastname;
  }

  const getNickname = (user) => {
    return user?.profiles?.[0]?.profileId?.nickname;
  }

  const getEmail = (user) => {
    return user?.email;
  }

  const getKYCpending = (user) => {
    return user?.identities?.find((identity) => (identity.type === "kyc" && identity.status !== "verified"));
  }

  const upgradeUser = async () => {
    try {
      await dispatch(upgraderUser({ userId: userInfo?._id, level: "kyc", identityId: "anIDhere" })).unwrap();
      notifySuccess("User successfully upgraded!");
    } catch (e) {
      console.error(e);
      notifyError(e);
    }
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Row>
            <Col className="col-xl-2 col-lg-2 col-md-auto col-sm-auto col-xs-auto">
              <div> {getFirstmame(userInfo)} </div> <div> {getLastname(userInfo)} </div>
            </Col>

            <Col className="col-xl-2 col-lg-2 col-md-auto col-sm-auto col-xs-auto">
              {getNickname(userInfo)}
            </Col>

            <Col className="col-xl-2 col-lg-2 col-md-auto col-sm-auto col-xs-auto">
              {getEmail(userInfo)}
            </Col>

            <Col className="col-xl-2 col-lg-2 col-md-auto col-sm-auto col-xs-auto">
              {userInfo.role}
            </Col>

            <Col className="col-xl-2 col-lg-2 col-md-auto col-sm-auto col-xs-auto">
              {userInfo.level}
            </Col>

            <Col className="col-xl-2 col-lg-2 col-md-auto col-sm-auto col-xs-auto text-end">
              {getKYCpending(userInfo) && <Button onClick={upgradeUser}>{t("users.upgrade-button")}</Button>}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>

  );
}

export default UserPreview;