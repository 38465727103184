import React from "react";
import { Row } from "reactstrap";
import AssetsGridItem from "./GridItem";

const AssetsGrid = ({ assets }) => {
  return (
    <Row>
      {assets.map((asset, index) => (
        <AssetsGridItem key={index} asset={asset} />
      ))}
    </Row>
  );
};

export default AssetsGrid;
