import React from "react";
import { Table } from "reactstrap";
import { useTranslation } from "react-i18next";
import AssetsListItem from "./ListItem";
import { tableHeaders } from "./constants";

const AssetsList = ({ assets }) => {
  const { t } = useTranslation();

  return (
    <div className="table-responsive">
      <Table className="project-list-table table-nowrap align-middle table-borderless">
        <thead>
          <tr>
            <th scope="col" style={{ width: "100px" }}>
              #
            </th>

            {tableHeaders.map((tableHeader, index) => (
              <th key={index} scope="col">
                {t(tableHeader.label)}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {assets.map((asset, index) => (
            <AssetsListItem key={index} asset={asset} />
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default AssetsList;
