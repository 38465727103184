import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import FormikForm from "../../components/form/FormikForm";
import { SingleSelect, TextInput } from "../../components/form/FormikInputs";
import { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { app, ethereum } from "../../constants/appSettings";
import { blockchainsDev, blockchainsProd } from "../../constants/networks";
import { getDefaultRoyalty, initializeContract, setDefaultRoyalty } from "../../store/EthereumSlice";
import { useDispatch } from "react-redux";
import { getChain } from "../../helpers/assets";
import { useLoader } from "../../providers/loader";
import { notifyError, notifySuccess } from "../../helpers/notifications";


const validationSchema = Yup.object().shape({
  ownershare: Yup.number()
    .required("Share is required")
    .min(0, "A positive share is needed")
    .max(10000, "Share must be up to 1000 or less").test('should-add-to-10000', 'Basis points should add up to 10000', (value, context) => {
      return (context.parent.ownershare + context.parent.creatorshare + context.parent.platformshare === 10000);
    }),
  creatorshare: Yup.number()
    .required("Share is required")
    .min(0, "A positive share is needed")
    .max(10000, "Share must be up to 1000 or less").test('should-add-to-10000', 'Basis points should add up to 10000', (value, context) => {
      return (context.parent.ownershare + context.parent.creatorshare + context.parent.platformshare === 10000);
    }),
  platformshare: Yup.number()
    .required("Share is required")
    .min(0, "A positive share is needed")
    .max(10000, "Share must be up to 1000 or less").test('should-add-to-10000', 'Basis points should add up to 10000', (value, context) => {
      return (context.parent.ownershare + context.parent.creatorshare + context.parent.platformshare === 10000);
    }),
  blockchains: Yup.string().required()
});

const initialValues = {
  ownershare: 0,
  creatorshare: 0,
  platformshare: 0,
  blockchains: ""
}

const blockchains = ((app.environment === "prod") || (app.environment === "pre")) ? blockchainsProd : blockchainsDev;

const Settings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setLoading, setText } = useLoader();
  const [formData, setFormData] = useState(initialValues);

  const getDefaultRoyalties = useCallback(async (chain) => {
    setLoading(true);
    setText("Loading...");
    try {
      await dispatch(initializeContract({ contractName: "SaysoonMatch", chainId: getChain({ blockchain: chain }) })).unwrap();
      const info = await dispatch(getDefaultRoyalty({})).unwrap();

      setFormData({
        ownershare: info[0],
        creatorshare: info[1],
        platformshare: info[2],
        blockchains: chain
      });
    } catch (e) {
      notifyError(e);
      console.error('error', e);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleSubmit = async (values) => {
    setLoading(true);
    setText("Updating...")
    try {
      await dispatch(initializeContract({ contractName: "SaysoonMatch", chainId: getChain({ blockchain: values.blockchains }) })).unwrap();
      await dispatch(setDefaultRoyalty({
        receiverOwner: ethereum.platformAddress,
        receiverCreator: ethereum.platformAddress,
        receiverPlatform: ethereum.platformAddress,
        feeNumeratorOwner: values.ownershare,
        feeNumeratorCreator: values.creatorshare,
        feeNumeratorPlatform: values.platformshare
      })).unwrap();
      notifySuccess("Default royalties updated");
    } catch (e) {
      notifyError(e);
      console.error('error', e);
    }finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    // getDefaultRoyalties("saysoon");
    // perform the call here to update the formData
  })

  return (
    <div className="account-pages my-5 pt-sm-5">
      <Container>
        <div className="p-2 text-center text-info" style={{ fontSize: "2.5vh" }}>
          Default Shares
        </div>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <FormikForm
              initialValues={formData}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <TextInput
                name="ownershare"
                label="Owner Share"
                placeholder={"e.x. 10"}
              />
              <Row>
                <Col sm={6}>
                  <TextInput
                    name="creatorshare"
                    label="Creator Share"
                    placeholder={"e.x. 10"}
                  />
                </Col>
                <Col sm={6}>
                  <TextInput
                    name="platformshare"
                    label="Platform Share"
                    placeholder={"e.x. 10"}
                  />
                </Col>
              </Row>
              <Row>
                <SingleSelect
                  label="Network"
                  options={blockchains}
                  name="blockchains"
                  optionIdPrefix="blockchains"
                  withoutTranslation={true}
                  onChange={(option) => {
                    getDefaultRoyalties(option)
                  }}
                ></SingleSelect>
              </Row>
              <Row>
                <Button type="submit">Update</Button>
              </Row>
            </FormikForm>

          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Settings;
