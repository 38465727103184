import React from "react";
import { Container, Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import FormWrapperCard from "../../components/card/FormWrapper";
import LoginForm from "./LoginForm";

const Login = () => {
  const { t } = useTranslation();

  return (
    <div className="account-pages my-5 pt-sm-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <FormWrapperCard
              title="login-page.sign-in-title"
              description="login-page.sign-in-subtitle"
            >
              <LoginForm />

              <div className="mt-4 text-center">
                <Link to="/password-recovery" className="text-muted">
                  <i className="mdi mdi-lock me-1" />{" "}
                  {t("login-page.forgot-password")}
                </Link>
              </div>
            </FormWrapperCard>

            <div className="mt-5 text-center">
              © {new Date().getFullYear()} SAYSOON-CONSOLE
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
