import React from "react";
import { Link } from "react-router-dom";
import logoDarkImg from "../../assets/images/app-logo.png";
import logoLightImg from "../../assets/images/app-logo-light.png";
import logoDarkImgSmall from "../../assets/images/app-logo-sm.png";
import logoLightImgSmall from "../../assets/images/app-logo-sm-light.png";

const Logo = () => {
  return (
    <div className="navbar-brand-box">
      <Link to="/" className="logo logo-dark">
        <span className="logo-sm">
          <img src={logoDarkImgSmall} alt="Saysoon" height="24" />
        </span>

        <span className="logo-lg">
          <img src={logoDarkImg} alt="Saysoon" height="30" />
        </span>
      </Link>

      <Link to="/" className="logo logo-light">
        <span className="logo-sm">
          <img src={logoLightImgSmall} alt="Saysoon" height="24" />
        </span>

        <span className="logo-lg">
          <img src={logoLightImg} alt="Saysoon" height="30" />
        </span>
      </Link>
    </div>
  );
};

export default Logo;
