import React, { useState } from "react";
import {
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { useField, isFunction } from "formik";
import { useTranslation } from "react-i18next";
import classnames from "classnames";

import "./FormikInputs.scss";

const FormikFormGroup = ({ label = "", hasError, children }) => {
  const { t } = useTranslation();

  return (
    <FormGroup className="formik-form-group">
      {label && (
        <Label className={classnames({ "label-error": hasError })}>
          {t(label)}
        </Label>
      )}

      {children}
    </FormGroup>
  );
};

const TextInput = ({ label = "", placeholder = "", onChange, ...rest }) => {
  const { t } = useTranslation();
  const [field, meta] = useField(rest);

  const handleChange = (event) => {
    field.onChange(event);

    if (isFunction(onChange)) onChange(event.target.value);
  };

  return (
    <FormikFormGroup label={label} hasError={meta.touched && meta.error}>
      <Input
        className={`${meta.touched && meta.error ? "error" : ""}`}
        name={field.name}
        value={field.value}
        placeholder={t(placeholder)}
        onChange={handleChange}
        {...rest}
      />

      {meta.touched && meta.error && (
        <div className="invalid-feedback d-block">{t(meta.error)}</div>
      )}
    </FormikFormGroup>
  );
};

const PasswordInput = ({ label = "", placeholder = "", onChange, ...rest }) => {
  const { t } = useTranslation();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [field, meta] = useField(rest);

  const handleChange = (event) => {
    field.onChange(event);

    if (isFunction(onChange)) onChange(event.target.value);
  };

  return (
    <FormikFormGroup label={label} hasError={meta.touched && meta.error}>
      <InputGroup>
        <Input
          className={`${meta.touched && meta.error ? "error" : ""}`}
          name={field.name}
          value={field.value}
          type={passwordVisible ? "text" : "password"}
          placeholder={t(placeholder)}
          onChange={handleChange}
          {...rest}
        />

        <InputGroupAddon
          className="password-btn"
          addonType="append"
          onClick={() => setPasswordVisible(!passwordVisible)}
        >
          <InputGroupText>
            <i
              className={`mdi ${passwordVisible ? "mdi-eye-outline" : "mdi-eye-off-outline"
                }`}
            />
          </InputGroupText>
        </InputGroupAddon>

        {meta.touched && meta.error && (
          <div className="invalid-feedback d-block">{t(meta.error)}</div>
        )}
      </InputGroup>
    </FormikFormGroup>
  );
};

const SingleSelect = ({
  label,
  emptyText = "select-item",
  inputClass = "sign__select",
  options,
  optionIdPrefix,
  withoutTranslation = false,
  onChange,
  ...rest
}) => {
  const { t } = useTranslation();
  const [field, meta] = useField(rest);

  const handleChange = (event) => {
    field.onChange(event);

    if (isFunction(onChange)) onChange(event.target.value);
  };

  return (
    <FormikFormGroup label={label}>
      <Input type="select"
        className={`${inputClass}${meta.touched && meta.error ? " error" : ""}${field.value === "" ? " text-gray-light-400" : ""
          }`}
        // as="select"
        name={field.name}
        value={field.value}
        onChange={handleChange}
        {...rest}
      >
        <option id={`${optionIdPrefix}option-empty`} value="" disabled>
          {t(emptyText)}
        </option>

        {options.map((item, index) => (
          <option
            key={index}
            id={`${optionIdPrefix}option-${index}`}
            className="text-white"
            value={item.value}
          >
            {withoutTranslation ? item.label : t(item.label)}
          </option>
        ))}
      </Input>
      {meta.touched && meta.error && (
        <div className="invalid-feedback d-block">
          {t(meta.error)}
        </div>
      )}
    </FormikFormGroup>
  );
};

export { TextInput, PasswordInput, SingleSelect };
