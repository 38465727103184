import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api";

export const getUsers = createAsyncThunk(
  "user/getUsers",
  async ({ }, { rejectWithValue }) => {
    try {
      const response = await api.UserService.retrieveUsers({});
      return response.body.data;
    } catch (error) {
      return rejectWithValue(error?.body?.error || error);
    }
  }
);

export const upgraderUser = createAsyncThunk(
  "user/upgradeUser",
  async ({ userId, level, identityId }, { rejectWithValue }) => {
    try {
      const response = await api.UserService.upgradeUserLevel({ userId, level, identityId });
      return response.body.data;
    } catch (error) {
      return rejectWithValue(error?.body?.error || error);
    }
  }
);

let initialState = {
  status: "",
  error: {},
  users: {},
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: {
    /* ---- GET_USERS ---- */
    [String(getUsers.pending)]: (state, action) => {
      state.status = "loading";
    },
    [String(getUsers.fulfilled)]: (state, action) => {
      state.status = "success";
      state.users = action.payload;
    },
    [String(getUsers.rejected)]: (state, action) => {
      state.status = "error";
      state.error = action.payload;
      state.users = "";
    },
    /* ---- GET_USERS ---- */
    [String(upgraderUser.pending)]: (state, action) => {
      state.status = "loading";
    },
    [String(upgraderUser.fulfilled)]: (state, action) => {
      state.status = "success";
      state.users = action.payload;
    },
    [String(upgraderUser.rejected)]: (state, action) => {
      state.status = "error";
      state.error = action.payload;
      state.users = "";
    },
  },
});

export default userSlice.reducer;
