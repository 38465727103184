import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import ReduxProvider from "./providers/redux";
import MenuProvider from "./providers/menu";
import Routes from "./routes";
import { Toaster } from "react-hot-toast";

import "./i18n";
import "./assets/scss/theme.scss";
import AuthWrapper from "./components/AuthWrapper";

const App = () => {
  return (
    <ReduxProvider>
      <Router>
        <AuthWrapper>
          <MenuProvider>
            <Toaster />
            <Routes />
          </MenuProvider>
        </AuthWrapper>
      </Router>
    </ReduxProvider>
  );
};

export default App;
