import swaggerProps from "../plugins/swagger-client";
export default class UserService {
  async loginUser(payload) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Auth.login(
          {},
          swaggerProps.buildRequest(payload),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

  async retrieveUser(userId) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.User.retrieveUser(
          { userId },
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async retrieveUsers(params = {}) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.User.retrieveUsers(
          params,
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async upgradeUserLevel(params = {}) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Auth.confirmUpgradeUserLevel(
          params,
          swaggerProps.buildRequest({ identityId: params.identityId }),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }
}
