import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown, DropdownToggle } from "reactstrap";
import LanguageDropdownList from "./DropdownList";
import languages from "../../constants/languages";

const isLanguageValid = (langObject, lang) => {
  return Object.keys(langObject).includes(lang);
};

const LanguageDropdown = () => {
  const { i18n } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("en");

  const changeLanguage = useCallback(
    (lang) => {
      if (isLanguageValid(i18n.services.resourceStore.data, lang)) {
        i18n.changeLanguage(lang);
        // localStorage.setItem("nft-gallery/I18N_LANGUAGE", lang)
        setSelectedLanguage(lang);
      }
    },
    [i18n]
  );

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={() => setDropdownOpen(!dropdownOpen)}
      className="d-inline-block"
    >
      <DropdownToggle className="btn header-item waves-effect" tag="button">
        <img
          src={languages[selectedLanguage].flag}
          alt="Language"
          height="16"
          className="me-1"
        />

        <span className="align-middle">
          {languages[selectedLanguage].label}
        </span>
      </DropdownToggle>

      <LanguageDropdownList
        languages={languages}
        selectedLanguage={selectedLanguage}
        onChangeLanguage={changeLanguage}
      />
    </Dropdown>
  );
};

export default LanguageDropdown;
