import React from "react";
import { Redirect } from "react-router-dom";
import Dashboard from "../pages/dashboard";
import Assets from "../pages/assets";
import AssetDetails from "../pages/assetDetails";
import Login from "../pages/login";
import Settings from "../pages/settings/settings";
import Users from "../pages/users";
import UserDetails from "../components/userDetails";

const authRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/assets", component: Assets },
  { path: "/assets/:assetId", component: AssetDetails },
  { path: "/settings", component: Settings },
  { path: "/users", component: Users },
  { path: "/user-details", component: UserDetails },
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [{ path: "/login", component: Login }];

export { authRoutes, publicRoutes };
