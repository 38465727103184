import React from "react";
import { Link } from "react-router-dom";
import AssetCover from "../../components/asset/AssetCover";
import SaleTypeBadge from "../../components/badge/SaleType";
import { assetDetailsPath } from "../../constants/assets";
import { getAssetPrice } from "../../helpers/assets";

const AssetsListItem = ({ asset }) => {
  return (
    <tr>
      <td>
        <AssetCover asset={asset} className="avatar-sm" />
      </td>

      <td className="text-truncate font-size-14 h5">
        <Link to={`${assetDetailsPath}/${asset._id}`} className="text-dark">
          {asset.title}
        </Link>
      </td>

      <td className="text-truncate font-size-13" style={{ maxWidth: "200px" }}>
        {asset.description}
      </td>

      <td className="text-truncate font-size-13">
        {`@${asset.ownerId.nickname}`}
      </td>

      <td className="text-truncate font-size-13">{getAssetPrice(asset)}</td>

      <td className="text-truncate font-size-13">
        <SaleTypeBadge saleType={asset.saleType} />
      </td>

      <td className="text-truncate font-size-13">{asset.likes}</td>
    </tr>
  );
};

export default AssetsListItem;
