import React from "react";
import { useTranslation } from "react-i18next";

const PageTitle = ({ text, textParams = {}, children }) => {
  const { t } = useTranslation();

  return (
    <span className="h4">
      {t(text, textParams)} {children}
    </span>
  );
};

export default PageTitle;
