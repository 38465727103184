import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import MenuContext, { MenuActionContext } from "./context";
import { isMobile, changeSidebarType, changeSidebarTheme } from "./helpers";

const initialTheme = "dark";
const initialType = "default";

const MenuProvider = ({ children }) => {
  const { pathname } = useLocation();
  const [mobile] = useState(isMobile());
  const [sidebarTheme, setSidebarTheme] = useState(initialTheme);
  const [sidebarType, setSidebarType] = useState(initialType);

  const toggleMenu = useCallback(() => {
    if (sidebarType === "default") {
      changeSidebarType("condensed", mobile);
      setSidebarType("condensed");
    } else if (sidebarType === "condensed") {
      changeSidebarType("default", mobile);
      setSidebarType("default");
    }
  }, [sidebarType, mobile]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    changeSidebarTheme(sidebarTheme);
  }, [sidebarTheme]);

  return (
    <MenuActionContext.Provider value={{ setSidebarTheme, toggleMenu }}>
      <MenuContext.Provider value={{ sidebarTheme }}>
        {children}
      </MenuContext.Provider>
    </MenuActionContext.Provider>
  );
};

export const useMenuContext = () => useContext(MenuContext);

export const useMenuAction = () => useContext(MenuActionContext);

export default MenuProvider;
