const menuItems = [
  {
    text: "menu.dashboard",
    icon: "bx bx-home-circle",
    route: "/dashboard",
  },
  {
    text: "menu.assets",
    icon: "bx bx-file",
    route: "/assets",
  },
  {
    text: "menu.users",
    icon: "bx bx-user",
    route: "/users",
  },
  {
    text: "menu.settings",
    icon: "bx bx-cog",
    route: "/settings",
  }
];

export default menuItems;
