export const tableHeaders = [
  { label: "title" },
  { label: "description" },
  { label: "owner" },
  { label: "price" },
  { label: "sale-type" },
  { label: "likes" },
];

export const viewEnum = { GRID: "grid", LIST: "list" };

export const viewOptions = [
  { key: viewEnum.GRID, icon: "bx bxs-grid" },
  { key: viewEnum.LIST, icon: "bx bx-list-ul" },
];
