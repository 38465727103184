export const blockchains = [
  { value: "ethereum", label: "Ethereum" },
  { value: "ropsten", label: "Ropsten" },
  { value: "rinkeby", label: "Rinkeby" },
  { value: "saysoon", label: "Saysoon" },
  { value: "polygon", label: "Polygon" },
  { value: "mumbai", label: "Mumbai" },
  { value: "goerli", label: "Goerli" }
];

export const blockchainsDev = blockchains.filter(el => el.value != "polygon" && el.value != "ethereum");

export const blockchainsProd = blockchains.filter(el => el.value == "saysoon" || el.value == "polygon");