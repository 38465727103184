import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api";

export const retrieveAssets = createAsyncThunk(
  "asset/retrieveAssets",
  async (params = {}, { rejectWithValue }) => {
    try {
      const response = await api.AssetService.retrieveAssets(params);
      return response.body.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const retrieveAsset = createAsyncThunk(
  "asset/retrieveAsset",
  async ({ assetId }, { rejectWithValue }) => {
    try {
      const response = await api.AssetService.retrieveAsset(assetId);
      return response.body.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  status: "",
  error: {},
  assets: [],
  currentAsset: {},
};

const assetSlice = createSlice({
  name: "asset",
  initialState,
  reducers: {},
  extraReducers: {
    /* ---- GET_ASSETS ---- */
    [String(retrieveAssets.pending)]: (state, action) => {
      state.status = "loading";
    },
    [String(retrieveAssets.fulfilled)]: (state, action) => {
      state.status = "success";
      state.assets = action.payload;
    },
    [String(retrieveAssets.rejected)]: (state, action) => {
      state.status = "error";
      state.error = action.payload;
      state.assets = [];
    },
    /* ---- GET_ASSET ---- */
    [String(retrieveAsset.pending)]: (state, action) => {
      state.status = "loading";
    },
    [String(retrieveAsset.fulfilled)]: (state, action) => {
      state.status = "success";
      state.currentAsset = action.payload;
    },
    [String(retrieveAssets.rejected)]: (state, action) => {
      state.status = "error";
      state.error = action.payload;
      state.currentAsset = {};
    },
  },
});

export default assetSlice.reducer;
