import React, { useState } from "react";
import { Dropdown, DropdownToggle } from "reactstrap";
import profileImg from "../../assets/images/logo.svg";
import ProfileDropdownList from "./DropdownList";

const ProfileDropdown = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [username] = useState("admin");

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={() => setDropdownOpen(!dropdownOpen)}
      className="d-inline-block"
    >
      <DropdownToggle
        className="btn header-item waves-effect"
        id="page-header-user-dropdown"
        tag="button"
      >
        <img
          src={profileImg}
          className="rounded-circle header-profile-user"
          alt="Profile Avatar"
        />

        <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>

        <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
      </DropdownToggle>

      <ProfileDropdownList />
    </Dropdown>
  );
};

export default ProfileDropdown;
