import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import classNames from "classnames";

const SidebarMenuLink = ({ route, text, icon, active = false }) => {
  const { t } = useTranslation();

  return (
    <Link
      to={route}
      className={classNames({
        "waves-effect": true,
        "mm-active": active,
      })}
    >
      <i className={icon}></i>

      <span>{t(text)}</span>
    </Link>
  );
};

export default SidebarMenuLink;
