import React from "react";
import { Switch, Redirect } from "react-router-dom";
import AuthMiddleware from "./AuthMiddleware";
import { authRoutes, publicRoutes } from "./allRoutes";
import { AuthLayout, PublicLayout } from "../layouts";

const Routes = () => {
  return (
    <Switch>
      {publicRoutes.map((route, index) => (
        <AuthMiddleware
          path={route.path}
          layout={PublicLayout}
          component={route.component}
          key={index}
          isRouteProtected={false}
        />
      ))}

      {authRoutes.map((route, index) => (
        <AuthMiddleware
          path={route.path}
          layout={AuthLayout}
          component={route.component}
          key={index}
          isRouteProtected={true}
          exact
        />
      ))}

      <Redirect to="/pages-404" />
    </Switch>
  );
};

export default Routes;
