import React from "react";
import { Formik, Form } from "formik";

const FormikForm = ({
  innerRef,
  initialValues,
  validate,
  onSubmit,
  children,
  ...rest
}) => {
  return (
    <Formik
      innerRef={innerRef}
      enableReinitialize={true}
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
      {...rest}
    >
      <Form>{children}</Form>
    </Formik>
  );
};

export default React.memo(FormikForm);
