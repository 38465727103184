import React from "react";
import { DropdownMenu, DropdownItem } from "reactstrap";

const LanguageDropdownList = ({
  languages,
  selectedLanguage,
  onChangeLanguage,
}) => {
  return (
    <DropdownMenu className="language-switch" right>
      {Object.keys(languages).map((key) => (
        <DropdownItem
          key={key}
          onClick={() => onChangeLanguage(key)}
          className={`notify-item ${selectedLanguage === key ? "active" : "none"}`}
        >
          <img
            src={languages[key].flag}
            alt="language"
            className="me-1"
            height="12"
          />

          <span className="align-middle">{languages[key].label}</span>
        </DropdownItem>
      ))}
    </DropdownMenu>
  );
};

export default LanguageDropdownList;
