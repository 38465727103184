import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import SimpleBar from "simplebar-react";
import SidebarMenuLink from "./MenuLink";
import menuItems from "../../constants/menuItems";

const SidebarMenu = () => {
  const location = useLocation();
  const simpleBarRef = useRef();

  useEffect(() => {
    simpleBarRef.current.recalculate();
  });

  return (
    <SimpleBar style={{ maxHeight: "100%" }} ref={simpleBarRef}>
      <div id="sidebar-menu">
        <ul id="side-menu" className="list-unstyled">
          {menuItems.map((item, index) => (
            <li key={index}>
              <SidebarMenuLink
                route={item.route}
                text={item.text}
                icon={item.icon}
                active={location.pathname === item.route}
              />
            </li>
          ))}
        </ul>
      </div>
    </SimpleBar>
  );
};

export default SidebarMenu;
