import React from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { viewOptions } from "./constants";

const AssetsPageHeader = ({ view, onViewChange }) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex justify-content-between align-items-center">
      <h4>{t("assets-page.title")}</h4>

      <div className="ml-3">
        {viewOptions.map((viewOption, index) => (
          <i
            key={index}
            className={classnames(
              `${viewOption.icon}`,
              "font-size-24 external-event me-1",
              { "text-primary": viewOption.key === view }
            )}
            onClick={() => onViewChange(viewOption.key)}
          />
        ))}
      </div>
    </div>
  );
};

export default AssetsPageHeader;
