/* eslint-disable no-underscore-dangle */

/* TBD: use .env file to set the env variables */
const {
  REACT_APP_API_PROTOCOL,
  REACT_APP_API_HOST,
  REACT_APP_API_PORT,
  REACT_APP_API_BASEPATH,
  REACT_APP_INFURA_PROJECT_ID,
  REACT_APP_VIGIL_PROJECT_ID,
  REACT_APP_SAYSOON_EXPLORER,
  REACT_APP_MUMBAI_EXPLORER,
  REACT_APP_POLYGON_EXPLORER,
  REACT_APP_ROPSTEN_EXPLORER,
  REACT_APP_RINKEBY_EXPLORER,
  REACT_APP_SAYSOON_RPC,
  REACT_APP_PLATFORM_ADDRESS,
  REACT_APP_PLATFORM_PK,
  REACT_APP_ETHEREUM_ENVIRONMENT,
  REACT_APP_PLATFORM_MNEMONIC,
  REACT_APP_ENVIRONMENT,
  REACT_APP_ID
} = window._env_;

console.log('ENV:', REACT_APP_API_PROTOCOL, REACT_APP_API_HOST, REACT_APP_API_PORT, REACT_APP_API_BASEPATH, REACT_APP_ENVIRONMENT, REACT_APP_ETHEREUM_ENVIRONMENT);

const app = {
  environment: REACT_APP_ENVIRONMENT || "dev",
  id: REACT_APP_ID
}
// API domain
const api = {
  protocol: REACT_APP_API_PROTOCOL,
  host: REACT_APP_API_HOST,
  port: REACT_APP_API_PORT,
  basePath: REACT_APP_API_BASEPATH
};

const ethereum = {
  platformAddress: REACT_APP_PLATFORM_ADDRESS,
  mnemonicPhrase: REACT_APP_PLATFORM_MNEMONIC,
  platformPK: REACT_APP_PLATFORM_PK,
  saysoonUrl: REACT_APP_SAYSOON_RPC,
  goerliUrl: `https://goerli.infura.io/v3/${REACT_APP_INFURA_PROJECT_ID}`,
  rinkebyUrl: `https://rinkeby.infura.io/v3/${REACT_APP_INFURA_PROJECT_ID}`,
  ropstenUrl: `https://ropsten.infura.io/v3/${REACT_APP_INFURA_PROJECT_ID}`,
  mumbaiUrl: `https://rpc-mumbai.maticvigil.com/v1/${REACT_APP_VIGIL_PROJECT_ID}`,
  polygonUrl: `https://rpc-mainnet.maticvigil.com/v1/${REACT_APP_VIGIL_PROJECT_ID}`,
  environment: REACT_APP_ETHEREUM_ENVIRONMENT || "dev"
}

const explorers = {
  saysoon: REACT_APP_SAYSOON_EXPLORER || "https://explorer-dev.saysoon.com",
  mumbai: REACT_APP_MUMBAI_EXPLORER || "https://mumbai.polygonscan.com",
  polygon: REACT_APP_POLYGON_EXPLORER || "https://polygonscan.com",
  ropsten: REACT_APP_ROPSTEN_EXPLORER || "https://ropsten.etherscan.io",
  rinkeby: REACT_APP_RINKEBY_EXPLORER || "https://rinkeby.etherscan.io"
}

export { api, ethereum, explorers, app };


