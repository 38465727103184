import React from "react";
import { useMenuAction } from "../../providers/menu";

const ToggleButton = () => {
  const { toggleMenu } = useMenuAction();

  return (
    <button
      id="vertical-menu-btn"
      className="btn btn-sm px-3 font-size-16 header-item waves-effect"
      onClick={toggleMenu}
    >
      <i className="fa fa-fw fa-bars" />
    </button>
  );
};

export default ToggleButton;
