import React, { useEffect, useState } from "react";
import { getAssetCoverSrc } from "../../helpers/assets";

const AssetCover = ({ asset, ...rest }) => {
  const [src, setSrc] = useState("");
  const [alt, setAlt] = useState("not_available");
  const [title, setTitle] = useState("");

  useEffect(() => {
    setSrc(getAssetCoverSrc(asset, 400));
    setAlt(asset.title);
    setTitle(asset.title);
  }, [asset]);

  return <img {...rest} src={src} alt={alt} title={title} />;
};

export default AssetCover;
