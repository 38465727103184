import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { retrieveAssets } from "../../store/AssetSlice";
import AssetsPageHeader from "./PageHeader";
import AssetsGrid from "./Grid";
import AssetsList from "./List";
import { viewEnum } from "./constants";
import { notifyError } from "../../helpers/notifications";

const Assets = () => {
  const dispatch = useDispatch();
  const { assets } = useSelector((store) => store.asset);
  const [activeView, setActiveView] = useState(viewEnum.GRID);

  useEffect(() => {
    const fetchAssets = async () => {
      try {
        await dispatch(retrieveAssets()).unwrap();
      } catch (error) {
        notifyError(error);
        console.error(error);
      }
    };

    fetchAssets();
  }, [dispatch]);

  return (
    <React.Fragment>
      <AssetsPageHeader view={activeView} onViewChange={setActiveView} />

      <div className="mt-4">
        {activeView === viewEnum.GRID ? (
          <AssetsGrid assets={assets} />
        ) : (
          <AssetsList assets={assets} />
        )}
      </div>
    </React.Fragment>
  );
};

export default Assets;
