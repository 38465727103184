import UserService from "./UserService";
import AssetService from "./AssetService";
import EthereumService from "./EthereumService";

const api = {
  UserService: new UserService(),
  AssetService: new AssetService(),
  EthereumService: new EthereumService()
};

export default api;
