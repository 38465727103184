import { assetFileSize } from "../constants/assets";

export const getAssetCoverSrc = (asset, assetSize = assetFileSize, index = 0) =>
  (asset?.files &&
    asset.files[index]?.file &&
    asset.files[index]?.file?.sizes &&
    asset.files[index].file?.sizes[assetSize]?.url) ||
  "";

export const getAssetPrice = (asset) => {
  let coin;
  switch (asset.blockchain) {
    case "saysoon":
      coin = "SAY";
      break;
    case "ethereum":
    case "ropsten":
    case "rinkeby":
    case "goerli":
      coin = "ETH";
      break;
    case "polygon":
    case "mumbai":
      coin = "MATIC";
      break;
    default:
      coin = "N/A";
      break;
  }
  return `${asset.price} ${coin}`;
};

export const getStandard = (asset) => {
  switch (asset?.standard) {
    case "erc1155":
      return 1155;
    case "erc721":
      return 721;
    default:
      throw new Error(`Standard '${asset?.standard}' not supported `);
  }
}

export const getChain = (asset) => {
  switch (asset?.blockchain) {
    case "polygon":
      return 137;
    case "saysoon":
      return 4500;
    case "ropsten":
      return 3;
    case "mumbai":
      return 80001;
    case "rinkeby":
      return 4;
    case "goerli":
      return 5
    case "ethereum":
      return 1;
    default:
      throw new Error(`Blockchain '${asset?.blockchain}' not supported `);
  }
}