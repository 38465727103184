import React from "react";
import { Col, Row } from "reactstrap";
import UserPreview from "../userPreview";

const UserList = ({ users }) => {
  
  return (
    <React.Fragment>
      {users?.map((user, index) => (
        // <Col>
        <UserPreview key={index} userInfo={user}></UserPreview>
        // </Col>
      ))}
    </React.Fragment>
  )

}

export default UserList;