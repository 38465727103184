import React from "react";
import Logo from "./Logo";
import ToggleButton from "./ToggleButton";
import LanguageDropdown from "../language/Dropdown";
import ProfileDropdown from "../profile/Dropdown";

const Topbar = () => {
  return (
    <header id="page-topbar">
      <div className="navbar-header">
        <div className="d-flex">
          <Logo />

          <ToggleButton />
        </div>

        <div className="d-flex">
          <LanguageDropdown />

          <ProfileDropdown />
        </div>
      </div>
    </header>
  );
};

export default Topbar;
