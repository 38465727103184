import LoadingOverlay from "react-loading-overlay";
import { ScaleLoader } from "react-spinners";

const ActualLoader = ({ text }) => {
  return (
    <>
      <ScaleLoader
        height={40}
        width={4}
        radius={3}
        margin={3}
        color="#2a3042"
        secondaryColor="rgba(0, 0, 0, 0.44)"
      />

      {text !== "" && (
        <div
          style={{
            width: "29vh",
            height: "6vh",
            background: "#6B6B6B",
            border: "1px solid white",
            padding: "5%",
            boxSizing: "border-box",
            borderRadius: "0px",
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "18px",
          }}
        >
          {text}
        </div>
      )}
    </>
  );
};

const Loader = ({ loading, text, children }) => {
  return (
    <LoadingOverlay
      active={loading}
      spinner={
        <ActualLoader text={text}></ActualLoader>
      }
      styles={{
        wrapper: {
          width: "100%",
          height: loading ? "100vh" : "auto",
        },
        overlay: (base) => ({
          ...base,
          position: "fixed",
          zIndex: "1044"
        }),
      }}
    >
      {children}
    </LoadingOverlay >
  );
};

export default Loader;
